import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import Weblinks from "common/Weblinks.js";
import ImageGallery from "common/ImageGallery.js";
import RelatedContent from "common/RelatedContent.js";
import TopMeta from "common/TopMeta.js";
import Image, { imagePropTypes } from "common/Image.js";
import DateRange from "common/DateRange.js";
import { Link } from "react-router-dom";

export const eventPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["event"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	introduction: PropTypes.string,
	body: PropTypes.string,
	summary: PropTypes.string,
	startDate: PropTypes.number.isRequired,
	endDate: PropTypes.number,
	mainImage: imagePropTypes,
	images: PropTypes.arrayOf(imagePropTypes),
	weblinks: weblinksPropTypes,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	operations: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	subtitle: PropTypes.string,
	files: downloadsPropTypes,
	eventType: PropTypes.arrayOf(termPropTypes).isRequired,
	eventVenue: PropTypes.string,
	eventVenueLink: PropTypes.object,
	eventRegistrationLink: PropTypes.object,
	relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	ogImage: PropTypes.string,
	ogMediaImage: imagePropTypes,
	ogDescription: PropTypes.string
});

export const eventTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["event"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	mainImage: imagePropTypes,
	startDate: PropTypes.number,
	endDate: PropTypes.number
});

export default class Event extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: eventPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<TopMeta t={t} node={node} />
				{node.introduction && <ClickableDrupalContent content={node.introduction} />}
				<ImageGallery t={t} images={node.images} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class EventTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: eventTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { t, node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image image={node.mainImage} type={relatedContentView ? "related_content" : "teaser"} />
					)}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="start-end-date">
						<label className="show-for-sr">{t("Meta.label.dateRange")}</label>
						<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
					</div>
					<Meta t={t} node={node} />
				</div>
			</article>
		);
	}
}

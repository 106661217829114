import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import Languages from "common/Languages.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import Weblinks from "common/Weblinks.js";
import RelatedContent from "common/RelatedContent.js";
import Block from "common/Block.js";
import ImageGallery from "common/ImageGallery.js";
import { renderDate, renderDateIso } from "common/utils.js";
import { listPropTypes } from "nodes/listing/NodeList.js";
import { Link } from "react-router-dom";
import Form, { formPropTypes } from "nodes/Form.js";
import IframeWrapper from "common/IframeWrapper.js";
import { renderPageLists } from "nodes/Page.lists.js";
import {
	newsletterCategoryGroups,
	renderNewsletterSubscriptionManagement
} from "nodes/Page.newsletterSubscriptionManagement.js";

export const pagePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["page"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	introduction: PropTypes.string,
	body: PropTypes.string,
	isEmpact: PropTypes.bool,
	empactDescription: PropTypes.string,
	summary: PropTypes.string,
	mainImage: imagePropTypes,
	images: PropTypes.arrayOf(imagePropTypes),
	weblinks: weblinksPropTypes,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	subtitle: PropTypes.string,
	files: downloadsPropTypes,
	featuredOperations: PropTypes.arrayOf(PropTypes.object), // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	relatedContent: PropTypes.arrayOf(PropTypes.object), // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	form: formPropTypes,
	lists: PropTypes.arrayOf(listPropTypes),
	iframeSrc: PropTypes.string,
	extraFunctionality: PropTypes.arrayOf(
		PropTypes.oneOf(["MAP", "NEWSLETTER_SUBSCRIPTION_MANAGEMENT", "NEWSLETTER_SUBSCRIPTION_MANAGEMENT_REMOVE"])
	),
	newsletterCategoryGroups: newsletterCategoryGroups,
	ogImage: PropTypes.string,
	ogMediaImage: imagePropTypes,
	ogDescription: PropTypes.string
});

export const pageTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["page"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	mainImage: imagePropTypes
});

export default class Page extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: pagePropTypes,
		blocks: PropTypes.object,
		search: PropTypes.string
	};

	render() {
		const { t, node, blocks, search } = this.props;
		return (
			<Fragment>
				<Languages t={t} languages={node.languages} language={node.language} alias={node.alias} />
				{node.introduction && <ClickableDrupalContent content={node.introduction} />}
				<ImageGallery t={t} images={node.images} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				{node.iframeSrc && <IframeWrapper title={node.title} src={node.iframeSrc} />}
				{renderNewsletterSubscriptionManagement(t, node)}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				{node.isEmpact && (
					<div className="Empact" id="empact">
						<h2 className="show-for-sr">{t("Empact.heading")}</h2>
						{node.empactDescription ? (
							<ClickableDrupalContent content={node.empactDescription} />
						) : (
							<Block name="empact" blocks={blocks} />
						)}
					</div>
				)}
				{renderPageLists(t, node, search)}
				{node.form && <Form t={t} form={node.form} url={node.alias} />}
				<RelatedContent t={t} relatedContent={node.featuredOperations} section="featured_operations" />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class PageTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: pageTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { t, node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image image={node.mainImage} type={relatedContentView ? "related_content" : "teaser"} />
					)}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="publish-date">
						<label className="show-for-sr">{t("Meta.label.date")}</label>
						<time date={renderDateIso(node.published)}>{renderDate(node.published, t("locale.full"))}</time>
					</div>
					<Meta t={t} node={node} />
				</div>
			</article>
		);
	}
}

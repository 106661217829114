import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Languages from "common/Languages.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import Weblinks from "common/Weblinks.js";
import NodeImages from "common/NodeImages.js";
import RelatedContent from "common/RelatedContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import { Link } from "react-router-dom";
import { renderDate, renderDateIso } from "common/utils.js";

export const documentPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["document"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	languages: PropTypes.arrayOf(PropTypes.string).isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	subtitle: PropTypes.string,
	body: PropTypes.string,
	summary: PropTypes.string,
	mainImage: imagePropTypes,
	images: PropTypes.arrayOf(imagePropTypes),
	weblinks: weblinksPropTypes,
	documentType: PropTypes.arrayOf(termPropTypes).isRequired,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	organisations: PropTypes.arrayOf(termPropTypes),
	files: downloadsPropTypes,
	relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	documentReportType: PropTypes.arrayOf(termPropTypes),
	documentContent: termPropTypes,
	documentSerialNumber: PropTypes.string,
	ogImage: PropTypes.string,
	ogMediaImage: imagePropTypes,
	ogDescription: PropTypes.string,
	forthcoming: PropTypes.bool
});

export const documentTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["document"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	language: PropTypes.string, // TODO SOM: optional languages as backend expose them for home page teasers but not on listing.
	languages: PropTypes.arrayOf(PropTypes.string), // TODO SOM: optional languages as backend expose them for home page teasers but not on listing.
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	forthcoming: PropTypes.bool,
	mainImage: imagePropTypes
});

export default class Document extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: documentPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<Languages t={t} languages={node.languages} language={node.language} alias={node.alias} />
				<div className="documentColumnWrapper">
					<NodeImages images={node.images} type="full" />
					{node.body && <ClickableDrupalContent content={node.body} />}
				</div>
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class DocumentTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: documentTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { t, node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image
							image={node.mainImage}
							type={relatedContentView ? "related_content" : "portrait_teaser"}
						/>
					)}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					{!node.forthcoming && (
						<div className="publish-date">
							<label className="show-for-sr">{t("Meta.label.date")}</label>
							<time date={renderDateIso(node.published)}>
								{renderDate(node.published, t("locale.full"))}
							</time>
						</div>
					)}
					<Meta t={t} node={node} />
					{node.forthcoming && <span className="forthcoming-label">{t("forthcoming")}</span>}
				</div>
				{node.promoted && <span className="teaser-label">{t("promoted")}</span>}
			</article>
		);
	}
}

import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import Weblinks from "common/Weblinks.js";
import ImageGallery from "common/ImageGallery.js";
import RelatedContent from "common/RelatedContent.js";
import { Link } from "react-router-dom";

export const staffMemberPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["staff_member"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	body: PropTypes.string,
	summary: PropTypes.string,
	mainImage: imagePropTypes,
	images: PropTypes.arrayOf(imagePropTypes),
	weblinks: weblinksPropTypes,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	workAreas: PropTypes.arrayOf(termPropTypes),
	subtitle: PropTypes.string,
	files: downloadsPropTypes,
	relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	ogImage: PropTypes.string,
	ogMediaImage: imagePropTypes,
	ogDescription: PropTypes.string
});

export const staffMemberTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["staff_member"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	jobTitle: PropTypes.string,
	mainImage: imagePropTypes
});

export default class StaffMember extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: staffMemberPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<ImageGallery t={t} images={node.images} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class StaffMemberTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: staffMemberTeaserPropTypes
	};

	render() {
		const { node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && <Image image={node.mainImage} type="full" />}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<div>{node.jobTitle && <h3>{node.jobTitle}</h3>}</div>
					<div>
						<Link to={node.alias} className="button white more">
							{`Meet ${node.title}`}
						</Link>
					</div>
				</div>
			</article>
		);
	}
}

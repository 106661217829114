import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import ImageGallery from "common/ImageGallery.js";
import NodeList from "nodes/listing/NodeList.js";
import { Link } from "react-router-dom";

export const crimeAreasPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["crime_areas"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	body: PropTypes.string,
	images: PropTypes.arrayOf(imagePropTypes),
	backgroundImages: PropTypes.arrayOf(imagePropTypes),
	cssClass: PropTypes.string,
	lists: PropTypes.arrayOf(PropTypes.object), // Should have used listPropTypes but ReferenceError: listPropTypes is not defined
	ogImage: PropTypes.string,
	ogMediaImage: imagePropTypes,
	ogDescription: PropTypes.string
});

export const crimeAreasTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["crime_areas"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	mainImage: imagePropTypes,
	empact: PropTypes.bool,
	parent: PropTypes.string
});

export default class CrimeAreas extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: crimeAreasPropTypes,
		blocks: PropTypes.object,
		search: PropTypes.string
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<ImageGallery t={t} images={node.images} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				{node.lists &&
					node.lists.length > 0 &&
					node.lists.map((list, index) => <NodeList key={index} t={t} list={list} />)}
			</Fragment>
		);
	}
}

export class CrimeAreasTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: crimeAreasTeaserPropTypes
	};

	render() {
		const { t, node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		extraCssClass += !node.parent ? " parent" : "";
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && <Image image={node.mainImage} type="teaser" />}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					{node.parent && <p className="parent-title">{node.parent}</p>}
				</div>
				{node.empact && <span className="teaser-label">{t("Empact.label")}</span>}
			</article>
		);
	}
}
